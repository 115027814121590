import { useState } from "react";
import { Stack, TextField } from "@fluentui/react";
import { Send28Filled, Bookmark24Regular } from "@fluentui/react-icons";

import styles from "./QuestionInput.module.css";
import { ConversationStarterPanel } from "../../components/ConversationStarterPanel";

const CONVERSATION_STARTERS_TEXTS: string[] = [
    "¿Que circunstancias debe haber para poder demandar a un inquilino por impago?",
    "Necesito obtener jurisprudencia sobre un caso de blanqueo de capitales relacionado con criptomonedas ",
    "Una empresa ha despedido a un cliente mío por llegar tarde un día a su puesto de trabajo. ¿Cuáles son los derechos que tiene mi cliente?",
    "Crea un informe en el cual mencionas todas las normas que regulan la protección de datos en España. indicando un breve resumen de cada norma"
]


interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    displayConversationStarters: boolean,
    clearOnSend?: boolean;
}

export const QuestionInput = ({ onSend, disabled, placeholder, displayConversationStarters, clearOnSend }: Props) => {
    const [question, setQuestion] = useState<string>("");

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else  {
            setQuestion(newValue);
        }
    };

    const onConversationStarterClick= (i: number) => {
        setQuestion(CONVERSATION_STARTERS_TEXTS[i])
    };

    const getIcon = () => {return <Bookmark24Regular/>}

    const sendQuestionDisabled = disabled || !question.trim();

    return (
        <>
            { displayConversationStarters && 
            (<ConversationStarterPanel onClick={onConversationStarterClick} texts={CONVERSATION_STARTERS_TEXTS} icon={getIcon}/> ) }
            <Stack horizontal className={styles.questionInputContainer}>
                <TextField
                    className={styles.questionInputTextArea}
                    placeholder={placeholder}
                    multiline
                    resizable={false}
                    borderless
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                />
                <div className={styles.questionInputButtonsContainer}>
                    <div
                        className={`${styles.questionInputSendButton} ${sendQuestionDisabled ? styles.questionInputSendButtonDisabled : ""}`}
                        aria-label="Boton hacer preguntas"
                        onClick={sendQuestion}
                    >
                        <Send28Filled primaryFill="rgba(115, 118, 225, 1)" />
                    </div>
                </div>
            </Stack>
        </>
    );
};
