import { Text } from "@fluentui/react";

import styles from "./ConversationStarterPanel.module.css";

interface Props {
    onClick: (id: number) => void,
    itemsClass?: string,
    texts: string[];
    icon: () => any
}

export const ConversationStarterPanel = ({ onClick, itemsClass, texts, icon}: Props) => {
    return (
            <div className={styles.container}>
                {texts.map((starter, i) => {
                    return(
                    <div className={itemsClass || styles.item} onClick={() => onClick(i)}>
                        <div className={styles.itemicon}>
                            {icon()}
                        </div>
                        <div className={styles.itemtext}>
                            <Text>{starter}</Text>
                        </div>
                    </div>)
                    })}
            </div>
    );
};
