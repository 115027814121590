import React from 'react';
import styles from "./Unauthorized.module.css";

const Unauthorized = () => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h1 className={styles.title}>401</h1>
                <h2 className={styles.subtitle}>No autorizado.</h2>
                <p className={styles.message}>
                    Parece que no tienes acceso al chat. Por favor contacta a un administrador.
                </p>
            </div>
        </div>
    );
};

export default Unauthorized;
