import { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import styles from "./Chat.module.css";

import { chatApiGpt, AskResponse, ChatRequestGpt, FiltersData, getFacets } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { UserChatMessage } from "../../components/UserChatMessage";
import { ClearChatButton } from "../../components/ClearChatButton";
import { Filters } from "../../components/Filters";
import { ShowFiltersButton } from "../../components/ShowFiltersButton";

const userLanguage = navigator.language;
let error_message_text = "";
if (userLanguage.startsWith("pt")) {
    error_message_text = "Desculpe, tive um problema técnico com a solicitação. Por favor informar o erro a equipe de suporte. ";
} else if (userLanguage.startsWith("es")) {
    error_message_text = "Lo siento, yo tuve un problema con la solicitud. Por favor informe el error al equipo de soporte. ";
} else {
    error_message_text = "I'm sorry, I had a problem with the request. Please report the error to the support team. ";
}

const EXPIRATION_ERROR_MSG = "TokenExpired";
const ERROR_UNAUTHORIZED = "Unauthorized";
const MISSING_TOKEN_MSG = "MissingToken";

const Chat = () => {

    const [placeholderText, setPlaceholderText] = useState("");

    const lastQuestionRef = useRef<string>("");
    
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [questionPending, setQuestionPending] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);
    const [userId, setUserId] = useState<string>("");

    const triggered = useRef(false);

    const [chatStarted, setChatStarted] = useState<boolean>(false);

    const [authors, setAuthors] = useState<string[]>([]);
    const [collections, setCollections] = useState<string[]>([]);
    const [subjects, setSubjects] = useState<string[]>([]);
    const [fillFilters, setFillFilters] = useState(true);
    const [filtersVisible, setFiltersVisible] = useState(false);

    const toggleFilters = () => {
        setFiltersVisible(!filtersVisible);
    };

    const emptyFilters: FiltersData = {
        author: [],
        collection: [],
        subject: [],
        date_from: null,
        date_to: null
    };
    const [filters, setFilters] = useState<FiltersData>(emptyFilters);

    const filterCount = filters ? (filters.author.length + filters.collection.length + filters.subject.length
        + (filters.date_from != null ? 1 : 0) + (filters.date_to != null ? 1 : 0)) : 0;

    function isTokenException(e: any){
        return e.message == EXPIRATION_ERROR_MSG || e.message == MISSING_TOKEN_MSG;
    }

    function sendTokenExpiredMessage(){
        window.parent.postMessage('Token expirado', process.env.REACT_APP_ALLOWED_ORIGIN || '')
    }

    async function requestFacets() {
        try {
            console.log("Requesting facets...")
            const facets = await getFacets();
            console.log("facets: " + JSON.stringify(facets))
            setAuthors(facets.author);
            setCollections(facets.collection);
            setSubjects(facets.subject);
            setFillFilters(false);
        } catch (e: any) {
            
            if (isTokenException(e)) {
                sendTokenExpiredMessage()
            }
            else if (e.message == ERROR_UNAUTHORIZED) {
                navigate('unauthorized')
            } else {
                throw e;
            }
        }
    }

    const navigate = useNavigate();

    const makeApiRequestGpt = async (question: string, filters: FiltersData) => {

        setFiltersVisible(false);
        if (!chatStarted){
            console.log("Chat initiated with filters: " + JSON.stringify(filters))
            setChatStarted(true);
        }

        let isQuestionPending = false
        setQuestionPending(isQuestionPending);
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);

        try {
            const request: ChatRequestGpt = {
                threadId: userId,
                query: question,
                assistantId: 'auto',
                filters: filters
            };
            const result = await chatApiGpt(request);
            setAnswers([...answers, [question, result]]);
            setUserId(result.conversation_id);
        } catch (e: any) {
            if (isTokenException(e)) {
                isQuestionPending = true
                setQuestionPending(isQuestionPending);
                sendTokenExpiredMessage();
            } else if (e.message == ERROR_UNAUTHORIZED) {
                navigate('unauthorized')
            } else {
                setError(e);
            }
        } finally {
            setIsLoading(isQuestionPending);
        }
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setAnswers([]);
        setUserId("");
        setChatStarted(false);
        setFilters(emptyFilters);
    };

    useEffect(() => {
        const receiveMessage = async (event: any) => {
            // Verify the origin of the message
            if (event.origin !== process.env.REACT_APP_ALLOWED_ORIGIN) {
                console.warn("Origin not allowed. Expected: " + process.env.REACT_APP_ALLOWED_ORIGIN + " . Received: " + event.origin)
                return;
            }
            const { token } = event.data;
            localStorage.setItem('session_token', token);
            if (fillFilters){
                requestFacets();
            }
            if (questionPending) {
                makeApiRequestGpt(lastQuestionRef.current, filters)
            }
        };

        window.addEventListener('message', receiveMessage, false);
        
        if (fillFilters){
            requestFacets();
        }
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
        if (triggered.current === false) {
            triggered.current = true;
        }
        const language = navigator.language;
        if (language.startsWith("pt")) {
            setPlaceholderText("Escreva aqui sua pergunta");
        } else if (language.startsWith("es")) {
            setPlaceholderText("Escribe tu pregunta aqui");
        } else {
            setPlaceholderText("Write your question here");
        }
        return () => window.removeEventListener('message', receiveMessage, false);
    }, [isLoading, questionPending, fillFilters]);

    const onShowCitation = async (url: string) => {
        window.open(url,'_blank');
    };
    
    return (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
            </div>
            
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {answers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    <div className={styles.chatMessageGpt}>
                                        <Answer
                                            key={index}
                                            answer={answer[1]}
                                            isSelected={false}
                                            onCitationClicked={(url) => onShowCitation(url)}
                                            showSources={true}
                                        />
                                    </div>
                                </div>
                            ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error_message_text + error.toString()} onRetry={() => makeApiRequestGpt(lastQuestionRef.current, filters)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}
                                       
                    <div className={styles.chatInput}>
                        <div className={styles.filtersContainer}>
                            {filtersVisible ? 
                                <Filters
                                    onClose={toggleFilters} 
                                    setFilters={setFilters} 
                                    filters={filters} 
                                    isDisabled={chatStarted}
                                    collections={collections}
                                    authors={authors}
                                    subjects={subjects}
                                />
                                 :
                                <ShowFiltersButton onClick={toggleFilters}>Filtros{filterCount > 0 && ` (${filterCount})`}</ShowFiltersButton>
                            }
                        </div>
                        <QuestionInput 
                            clearOnSend placeholder={placeholderText}
                            disabled={isLoading}
                            onSend={question => makeApiRequestGpt(question, filters)}
                            displayConversationStarters = {!lastQuestionRef.current}
                            />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;
