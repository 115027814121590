import Select from 'react-select';
import styles from './Dropdown.module.css';

interface Props {
    label: string;
    options: string[];
    initialOptions?: string[];
    onChange: (selected: string[]) => void;
    disabled: boolean;
}

export const Dropdown = ({ label, options, initialOptions = [], onChange, disabled } : Props) => {
  
  const handleChange = (selected: any) => {
    const selectedValues = selected.map((option: any) => option.value);
    onChange(selectedValues);
  };

  return (
    <div className={styles.dropdown}>
      <label>{label}</label>
      <Select
        options={options.map(option => ({ value: option, label: option }))}
        value={initialOptions.map(option => ({ value: option, label: option }))}
        onChange={handleChange}
        isMulti
        className={styles.multiSelect}
        closeMenuOnSelect={false}
        isDisabled={disabled}
      />
    </div>
  );
}