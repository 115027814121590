import { AskResponseGpt, ChatRequestGpt, FacetsResponse } from "./models";

export async function chatApiGpt(options: ChatRequestGpt): Promise<AskResponseGpt> {
   
    const response = await fetch("/chats", {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({
            threadId: options.threadId,
            query: options.query,
            assistantId: options.assistantId,
            filters: options.filters
        })
    });

    const parsedResponse = await checkResponse(response)
    return parsedResponse;
}

export async function getFacets(): Promise<FacetsResponse> {

    const response = await fetch("/documents/facets", {
        method: 'GET',
        headers: getHeaders()
    });

    const data = await checkResponse(response)

    const author = data.author.map((item: { value: string }) => item.value);
    const subject = data.subject.map((item: { value: string }) => item.value);
    const collection = data.collection.map((item: { value: string }) => item.value);

    return {
        author,
        subject,
        collection
    };
}

function getHeaders(): any {
    let authToken = localStorage.getItem("session_token")
    return {
        "Content-Type": "application/json",
        ...(authToken ? { Authorization: authToken } : {})
      };
}

async function checkResponse(response: any): Promise<any> {
    const parsedResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse
}