import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './DateRange.module.css';

interface Props {
  onDateChange: (startDate: Date | null, endDate: Date | null) => void;
  initialStartDate?: Date | null;
  initialEndDate?: Date | null;
  disabled: boolean;
}

export const DateRange = ({ onDateChange, initialStartDate = null, initialEndDate = null, disabled } : Props) => {
  
  const handleStartDateChange = (date: Date | null) => {
    onDateChange(date, initialEndDate);
  };

  const handleEndDateChange = (date: Date | null) => {
    onDateChange(initialStartDate, date);
  };

  return (
    <div className={styles.dateRange}>
      <div className={styles.datePicker}>
        <label>Fecha desde:</label>
        <DatePicker selected={initialStartDate} onChange={handleStartDateChange} disabled={disabled} />
      </div>
      <div className={styles.datePicker}>
        <label>Fecha hasta:</label>
        <DatePicker selected={initialEndDate} onChange={handleEndDateChange} disabled={disabled} />
      </div>
    </div>
  );
};